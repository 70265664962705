import { createRouter, createWebHistory } from 'vue-router';
import store from './store/index.js'



const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/email-verify", component: () => import('./components/Auth/VerifyEmail.vue'), meta: { title: 'Account Verification', requireUnAuth: true }, query: { token: "" } },
        { path: "/reset-password", component: () => import('./components/Auth/ResetPassword.vue'), meta: { title: 'Reset Password', requireUnAuth: true }, query: { uidb64: "", token: "" } },
        { path: "/verifiaction-status", component: () => import('./components/Auth/signUpGlocal/LinkSuccess.vue') },
        { path: "/invite/:referralid", component: () => import('./components/Auth/Invite.vue') },
        { path: "/", component: () => import('./pages/LandingPageV2.vue'), name: "landing-page-V2", meta: { title: 'Glocal', requireUnAuth: true },
        beforeEnter: (to, from, next) => {
            store.getters['auth/isAuthenticated'] ? next("/discover") : next();
        },
        children: [
            { path: "/student-sign-up", component: () => import('./pages/LandingPageV2.vue'), name: "landing-page-V2", meta: { title: 'Glocal' } },
        ]
        },
        
        { name: "redirect-to-stripe", path: "/redirect-to-stripe", component: () => import('./components/LandingPageComponentsV2/StripeCard.vue'), query: { amount: "" } },
        { path: '/register', component: () => import('./pages/SignUpGlocal.vue'), meta: { title: 'Glocal - Register', requireUnAuth: true } },
        { path: '/user-authenticated', component: () => import('./pages/TheAuthenticated.vue'), requiresAuth: true },
        {
            path: '/user/:username', component: () => import('./pages/ProfilePage.vue'), meta: { title: 'Glocal - Profile', requiresAuth: true },
            children: [
                {
                    path: "",
                    name: "user-profile",
                    component: () => import('./components/ProfilePageComponentsV2/TheProfile.vue'),
                    meta: { title: 'Glocal - User Profile', requiresAuth: true }
                },
                {
                    path: "settings",
                    name: "user-settings",
                    component: () => import('./components/ProfilePageComponents/UserSettings/TheSettings.vue'),
                    meta: { title: 'Glocal - Settings', requiresAuth: true }
                }
            ]

        },
        {
            path: '/workspace', component: () => import('./pages/TheWorkspace.vue'), meta: { title: 'Glocal - Workspace', requiresAuth: true },
            children: [
                {
                    name: 'write',
                    path: "write",
                    component: () => import('./components/WorkspaceComponents/Write/WriteSection.vue'),
                    // meta: { title: 'Glocal - Workspace-Write' }
                },
                {
                    name: 'collaboration',
                    path: "collaboration",
                    component: () => import('./components/WorkspaceComponents/CollaborationCorner/CollaborationCorner.vue'),
                    // meta: { title: 'Glocal-Workspace-Collaboration' }
                },
                {
                    name: 'article-preview',
                    path: "article-preview",
                    component: () => import('./components/WorkspaceComponents/ArticleView/PreviewArticle.vue'),
                    // meta: { title: 'Glocal-Workspace-Article Preview' }
                }
            ]
        },
        { path: "/:username/financial-analysis", component: () => import('./pages/FinancialDashboard.vue'), meta: { title: 'Glocal - Financial - Dashboard', requiresAuth: true } },
        {
            path: "/discover", component: () => import('./pages/PersonalDailyBriefing.vue'),
            meta: { title: 'Glocal - Personal - Daily - Briefing', requiresAuth: true },
            children: [
              {
                path: ':topic/:id?/',
                component: () => import('./pages/PersonalDailyBriefing.vue'),
              },  
              { path: 'article/:topic/:articleId', 
                  component: () => import('./pages/ArticleReadView.vue'),
                  meta: { title: 'Glocal - Article', requiresAuth: true },
              },
            ]
        },
        { path: "/intelligence-dashboard", component: () => import('./pages/IntelligenceDashboard.vue'), meta: { title: 'Glocal - Intelligence - Dashboard', requiresAuth: true } },
        {
            path: "/engage", redirect: "/engage/impact", component: () => import('./pages/Engage.vue'), meta: { title: 'Glocal - Engage', requiresAuth: true },
            children: [
                {
                    path: "impact",
                    component: () => import('./components/EngageComponents/ImpactArticles.vue'),
                    // meta: { title: 'Glocal-Engage-Impact' }
                },
                {
                    path: "impact/:id",
                    component: () => import('./components/EngageComponents/ImpactArticles.vue'), // should be a child of above?
                    // meta: { title: 'Glocal-Engage-Impact' }
                },
                {
                    path: "fund-raiser",
                    component: () => import('./components/EngageComponents/FundRaiserArticles.vue'),
                    // meta: { title: 'Glocal-Engage-Fundraiser' }
                },

            ]
        },
        { path: "/activate-account", component: () => import('./components/Auth/ActivateAccount.vue'), meta: { title: 'Glocal - Invivte' } },
        { path: "/the-privacy", component: () => import('./components/Auth/pages/ThePrivacy.vue') },
        { path: "/the-policy", component: () => import('./components/Auth/pages/ThePolicy.vue') },
        { path: "/verify-email", component: () => import('./pages/EmailVerified.vue') },
        { path: "/set-new-password", component: () => import('./pages/SetNewPassword.vue') },
        { path: "/subscription", component: () => import('./components/Auth/TheSubscription.vue')},
        { path: "/checkout/success", component: () => import('./components/Auth/pages/TheSuccess.vue')},
        { path: "/checkout/fail", component: () => import('./components/Auth/pages/TheFailure.vue')},
        { path: "/invite-to-glocal", component: () => import('./components/PdbComponents/Invite.vue')},
        { path: "/trial-success", component: () => import('./components/Auth/FreeTrialSuccessful.vue') },
        { path: "/glocal-AI", component: () => import('./pages/GlocalAI.vue'), meta: { title: 'Glocal - AI' } },
        { path: '/glocal-AI/:id', component: () => import('./pages/GlocalAI.vue') },
        {path: '/network-feed',component: () => import('./pages/GlocalFeed.vue'), meta: { title: 'Glocal - Network Feed' } },
        {path: '/error',component: () => import('./pages/Error.vue'), meta: { title: 'Error' } },
        {
            path: "/folders", component: () => import('./components/GlocalFolders/GlocalFolders.vue'),
            name: 'folders',
            children: [
                {
                    path: ":class/:id?",
                    component: () => import('./components/GlocalFolders/GlocalFolders.vue'),
                    name: `classFolders`,
                },
                {
                    path: ":personal/:id?",
                    component: () => import('./components/GlocalFolders/GlocalFolders.vue'),
                    name: `personalFolders`,
                },
                
            ]
        },

    ]
});

router.beforeEach(async (to, from, next) => {

    if(store.getters['auth/isAuthenticated'] && store.getters["profile/userInformation"].length == 0){
       const res = await store.dispatch("profile/getUserInformation");
       if (res.data.is_paid === false) {
        await store.dispatch("auth/getSubscriptionPackages");
    }
    }
    if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
        if (to.query.topic) {
             store.commit(
               "discover/SET_TOPIC_URL",
               to.fullPath
             );
            next({ path: "/" });
        }
        else if (to.query.article) {
            store.commit("discover/SET_INVITED_ARTICLE_ID", to.query.article);
            next({ path: "/" });
        }
        else {
          next({ path: "/" });
        }
    }
    else if (to.meta.requiresAuth && store.getters['auth/isAuthenticated'] && store.getters['profile/isPaid']) {
        if (to.query.topic) {
            const path = `/discover${to.fullPath}`
            window.location.href = path
        }
        else if (to.query.article) {
                 const path = `/discover/article/${to.query.article}`;
                 window.location.href = path;
        }
        else {
            next()
         }
    }
    else if (to.hash === "#1" && !store.getters["auth/isAuthenticated"]) {
        store.dispatch("folders/setRequestedFolderId", to.params.id);
        next({path:"/"})
    }
    else {
        next()
    }
});

export default router;